<template>
  <mf-loading loader-type="dialog" :boolean-loader="$apollo.queries.product.loading">
    <base-page-layout title="Produtos de Segmento">
      <v-card>
        <v-card-title>
          {{ id ? 'Editar lista de produtos' : 'Nova lista de produtos' }}
        </v-card-title>
        <v-card-subtitle v-if="id" class="d-flex flex-column">
          <span>Alterado em: {{ updated_at | dateTime }}</span>
          <span>Alterado por: {{ updated_by_email ? updated_by_email : '-' }}</span>
        </v-card-subtitle>
        <v-card-text>
          <form>
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Nome"
              required
              outlined
              maxlength="70"
              counter
              hint="Esse será o mesmo nome usado para preencher o template na coluna segmento."
              persistent-hint
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            />

            <v-text-field
              v-model="description"
              :error-messages="descriptionErrors"
              label="Descrição"
              required
              outlined
              maxlength="200"
              counter
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
            />

            <select-industry ref="industrySelection" v-model="industry" required />

            <v-textarea
              v-model="eans"
              :error-messages="eanErrors"
              label="EANs"
              required
              outlined
              clearable
              auto-grow
              clear-icon="mdi-close-circle"
              hint="Use vírgula OU quebra de linha para separar mais de um valor."
              persistent-hint
              @input="$v.eans.$touch()"
              @blur="$v.eans.$touch()"
            />
          </form>
        </v-card-text>
      </v-card>

      <div class="d-flex flex-row align-center justify-end mt-2" style="gap: 8px;">
        <mf-button label="Cancelar" outlined color="error" @click="() => $router.push({ name: 'produtos-segmento' })" />
        <mf-action-buttons
          :primary-button="{
            text: id ? 'Salvar' : 'Inserir',
            action: submit,
            isVisible: true,
            isDisabled: false,
            isLoading: loading
          }"
        />
      </div>
    </base-page-layout>
  </mf-loading>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { QUERY_INDUSTRY_SEGMENT_PRODUCTS_BY_ID, MUTATION_UPSERT_INDUSTRY_SEGMENT_PRODUCTS } from '@/modules/industry/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    SelectIndustry: () => import('@/modules/industry/components/SelectIndustry.vue')
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    name: '',
    description: '',
    industry: {},
    eans: '',
    updated_at: Object,
    updated_by_email: '',
    arrayEans: []
  }),
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Nome é obrigatório.')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Descrição é obrigatória.')
      return errors
    },
    eanErrors() {
      const errors = []
      if (!this.$v.eans.$dirty) return errors
      !this.$v.eans.required && errors.push('EAN é obrigatório.')
      return errors
    }
  },
  apollo: {
    product: {
      query: QUERY_INDUSTRY_SEGMENT_PRODUCTS_BY_ID,
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        return { input: { indSegmentProductsId: this.id } }
      },
      skip() {
        return !this.id
      },
      update(result) {
        const product = result.industrySegmentProducts
        this.name = product.name
        this.description = product.description
        this.industry = product.industry
        this.eans = product.eans.join(', ')
        this.arrayEans = product.eans
        this.updated_at = new Date(product.updated_at).toISOString()
        this.updated_by_email = product.updated_by_email
      }
    }
  },
  methods: {
    async submit() {
      this.$v.$touch()
      this.$refs.industrySelection.$v.$touch()
      if (this.$v.$invalid || !this.$refs.industrySelection.valid()) return

      this.loading = true
      this.convertEansToArray()
      let mutation = MUTATION_UPSERT_INDUSTRY_SEGMENT_PRODUCTS
      let variables = {
        input: {
          name: this.name,
          description: this.description,
          eans: this.arrayEans,
          industry_id: this.industry._id
        }
      }
      if (this.id) {
        variables.input._id = this.id
      }

      try {
        await this.$apollo.mutate({
          mutation,
          variables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$snackbar({ message: `Lista de produtos salva com sucesso`, snackbarColor: 'success' })
        this.$router.push({ name: 'produtos-segmento' })
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao salvar lista de produtos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })

        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLError = error.graphQLErrors[0]
          const details = graphQLError.message || 'Detalhes do erro indisponíveis'
          console.error(details)
        }
      } finally {
        this.loading = false
      }
    },
    convertEansToArray() {
      this.$v.eans.$touch()
      this.arrayEans = []
      let converted = this.eans.replaceAll('[', '')
      converted = converted.replaceAll(']', '')
      converted = converted.replaceAll('"', '')
      converted = converted.replaceAll("'", '')
      converted = converted.replaceAll(' ', '')
      converted = converted.trim()
      if (converted.includes('\n')) {
        converted = converted.split('\n')
      } else if (converted.includes(',')) {
        converted = converted.split(',')
      } else {
        converted = [converted]
      }
      if (converted.length <= 0) return
      converted = converted.filter(ean => ean.length > 0)
      this.arrayEans.push(...converted)
      this.arrayEans.push(
        ...converted.map(ean => {
          return ean.startsWith('0') ? ean.substring(1, ean.length) : '0' + ean
        })
      )
      this.arrayEans = [...new Set(this.arrayEans)]
      this.arrayEans = this.arrayEans.map(ean => ean.replaceAll(',', ''))
    }
  },
  validations: {
    name: { required },
    description: { required },
    eans: { required }
  }
}
</script>
